import {useApolloClient, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useSystemMetricTags} from "../contexts/systemMetricTagsContext";
import {getConfigWizardTagFromTags} from "../utils/systemMetricTags";

const CREATE_SPACE = gql`
mutation createSpace($projectID: String!, $systemTemplateId: ID!, $displayName: String!, $slug: String!) {
  createSpace(
    displayName: $displayName
    slug: $slug
    projectID: $projectID
    systemTemplateId: $systemTemplateId
  ) { id }
}`;
const CREATE_SYSTEM_METRIC_ON_SPACE = gql`
mutation createSystemMetricOnSpace($projectID: String!, $spaceId: ID!, $systemMetricTemplateId: ID!, $payload: Json!,$payloadType: String!, $userInput: Json!, $aggregation: String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnSpace(
    projectID: $projectID
    spaceId: $spaceId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    payloadType: $payloadType
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
  ) { id }
}`;

const GET_SPACE_SYSTEM_METRICS_TEMPLATES = gql`
query getSpaceSystemMetrics {
  systemTemplates(systemTemplateNames: ["systemTemplate_space"]){
    id
    name
    systemMetricTemplates(where: { level: 0 }) {
        id
        name
        description
        displayName
        metric{
            id
            defaultOperationFormula
        }
    }
  }
}`;
const useCreateSpace = () => {
    const systemMetricTags = useSystemMetricTags();
    const [createSpaceMutation] = useMutation(CREATE_SPACE);
    const [createSystemMetricOnSpaceMutation] = useMutation(CREATE_SYSTEM_METRIC_ON_SPACE);
    const client = useApolloClient();

    const createSpace = async ({projectID, systemTemplateId, displayName, slug}) => {
        function getSpaceTemperatureAirProperties(systemMetricTemplates) {
            const systemMetricTemplate= systemMetricTemplates.filter(t => (t.name === "space_temperature_air"))[0];
            const systemMetricTemplateId = systemMetricTemplate.id;
            const aggregation = systemMetricTemplate.metric.defaultOperationFormula;

            const systemMetricTagCreatorConfigwizardId = getConfigWizardTagFromTags(systemMetricTags)
            const sensorMetricTagId = systemMetricTags.filter(t => (t.key === "function" && t.value === "sensor"))[0].id;
            const timewindowTagId = systemMetricTags.filter(t => (t.key === "timewindow" && t.value === "past"))[0].id;
            const payloadType = "timeseries";
            return {
                systemMetricTemplateId,
                systemMetricTagCreatorConfigwizardId,
                sensorMetricTagId,
                timewindowTagId,
                payloadType,
                aggregation
            };
        }

        const result = await createSpaceMutation({
            variables: {
                projectID,
                systemTemplateId,
                displayName,
                slug,
            },
        });
        const spaceId = result.data.createSpace.id;
        const systemMetricTemplates = await client.query({
            query: GET_SPACE_SYSTEM_METRICS_TEMPLATES
        });

        const {
            systemMetricTemplateId,
            systemMetricTagCreatorConfigwizardId,
            sensorMetricTagId,
            timewindowTagId,
            payloadType,
            aggregation
        } = getSpaceTemperatureAirProperties(systemMetricTemplates.data.systemTemplates[0].systemMetricTemplates);

        await createSystemMetricOnSpaceMutation({
            variables: {
                projectID,
                spaceId: spaceId,
                systemMetricTemplateId: systemMetricTemplateId,
                payload: {},
                payloadType: payloadType,
                userInput: {},
                aggregation: aggregation,
                systemMetricTags: [
                    {
                        "id": systemMetricTagCreatorConfigwizardId
                    },
                    {
                        "id": sensorMetricTagId
                    },
                    {
                        "id": timewindowTagId
                    }
                ],
                rawTrajectories: [],
                profileContextCreateOneWithoutSystemMetricConversionsInput: null
            }
        });

    }

    const createSystemMetricOnSpace = async (variables) => {
        await createSystemMetricOnSpaceMutation({
            variables,
        });
    };


    const getSystemMetricTemplateForSpaces = () => {
        return GET_SPACE_SYSTEM_METRICS_TEMPLATES
    }
    return {createSpace, createSystemMetricOnSpace, getSystemMetricTemplateForSpaces};
}

export default useCreateSpace;