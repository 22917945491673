import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks';

import gql from 'graphql-tag';
import moment from 'moment'

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import MuiForm from 'rjsf-material-ui';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh'
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfigControl from './config/ConfigControl'
import ConfigCalendar from './config/ConfigCalendar'
import ConfigSteerMetrics from './config/ConfigSteerMetrics'
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Divider from '@material-ui/core/Divider';
import AppSettings from '../AppSettings'

const BuildingFeatureTriggerUrl = AppSettings.BuildingFeatureTriggerUrl;
const axios = require('axios')
const DataInputBuildingFeatureList = props => {
    const [selectedFeatureId, setSelectedFeatureId] = useState(
        props.location.search.length > 1
            ? props.location.search.split("&")[0].split("=")[1]
            : "-none-"
    );
    const filter = props.filter
    const [loadingHistory, setLoadingHistory] = useState(false);
    //  const [ config, setConfig ] = useState(null)

    const respMe = useQuery(getSelectedBuildingFeatureList, {variables: {projectID: props.match.params.projectID}})
    const respEvents = useQuery(getSelectedBuildingEventList, {
        variables: {projectID: props.match.params.projectID},
        onCompleted: () => setLoadingHistory(false),
        notifyOnNetworkStatusChange: true
    })
    const respFeatures = useQuery(getFeatures, {variables: {category: filter}})
    const [createBuildingFeatureMutation] = useMutation(createBuildingFeature)
    const [activateBuildingFeatureMutation] = useMutation(activateBuildingFeature)
    const [deActivateBuildingFeatureMutation] = useMutation(deActivateBuildingFeature)
    const [updateBuildingFeatureMutation] = useMutation(updateBuildingFeature)
    const [deleteBuildingFeatureMutation] = useMutation(deleteBuildingFeature)
    // const [ runBuildingFeatureMutation ] = useMutation(runBuildingFeature)
    const refetchQuery = {
        query: getSelectedBuildingFeatureList,
        variables: {projectID: props.match.params.projectID}
    }
    const features = (respFeatures.error || respFeatures.loading || !respFeatures.data.features)
        ? []
        : respFeatures.data.features

    const buildingEvents = (respEvents.error || respEvents.loading || !respEvents.data?.building?.events)
        ? []
        : respEvents.data.building.events

    // const activeControl = (respMe.error || respMe.loading || !respMe.data?.me?.selectedBuilding?.activeControl  )
    // ? []
    // : respMe.data.me.selectedBuilding.activeControl

    // console.log(activeControl)


    const get_feature = (feature_id) => (features.find(f => f.id === feature_id))
    // const get_feature_button_names = (feature_id) => (
    //   get_feature(feature_id)
    //   ? Object.entries(get_feature(feature_id).configSchema.properties)
    //   .filter(([name, element]) => element.properties?.uitype?.default === "button")
    //   .map(([name, element]) => name)
    //   : [] )

    const get_feature_button_names = (feature_id) => {
        var condition = Object.keys(get_feature(feature_id) ? get_feature(feature_id).configSchema : {}).length !== 0
        return condition
            ? Object.entries(get_feature(feature_id).configSchema.properties)
                .filter(([, element]) => element.properties?.uitype?.default === "button")
                .map(([name]) => name)
            : []
    }
    const get_feature_config_schema = (feature_id) => ({
        type: "object",
        properties: Object.fromEntries(Object.entries(get_feature(feature_id)?.configSchema?.properties ? get_feature(feature_id).configSchema.properties : {})?.filter(([k]) => get_feature_button_names(feature_id).findIndex(name => name === k) < 0))
    })
    const get_category = (feature_id) => (get_feature(feature_id).featureTags.find(t => t.key === "category") ? get_feature(feature_id).featureTags.find(t => t.key === "category").value : "")
    const selectedBuilding = (respMe.error || respMe.loading || !respMe.data?.building)
        ? {}
        : respMe.data?.building;


    const get_building_feature = (feature_id) => (selectedBuilding.buildingFeatures.find(bf => bf.feature.id === feature_id))
    const get_building_feature_events = (feature_id) => (buildingEvents.filter(be => be.eventType.startsWith("io.deltaq.project.event.buildingfeature." + get_feature(feature_id).name)))
    const get_password_ui_schema = (schema) => {
        if (!schema || !schema.properties) return {};

        return Object.fromEntries(
            Object.entries(schema.properties)
                .map(([key, value]) => {
                    if (key.toLowerCase().includes('pass')) {
                        return [key, {...value, "ui:widget": "password"}];
                    }
                    return [key, value];
                })
        );
    };
    features.sort((a, b) => (
        get_category(a.id).localeCompare(get_category(b.id)) === 0
            ? a.name.localeCompare(b.name)
            : get_category(a.id).localeCompare(get_category(b.id))
    ))

    const handleFeatureChange = event => {
        window.history.replaceState(null, "dnergy Building Dashboard", '/buildings/' + selectedBuilding.projectID + '/datainput/features?fid=' + event.target.value)
        setSelectedFeatureId(event.target.value)
    }

    const handleHistoryRefetch = (e) => {
        e.stopPropagation();
        setLoadingHistory(true);
        respEvents.refetch();
    };

    const handleBuildingFeatureActivation = (building_feature_id) => {
        activateBuildingFeatureMutation({
            variables: {buildingFeatureId: building_feature_id},
            refetchQueries: [refetchQuery]
        })
    };

    const handleBuildingFeatureDeActivation = (building_feature_id) => {
        deActivateBuildingFeatureMutation({
            variables: {buildingFeatureId: building_feature_id},
            refetchQueries: [refetchQuery]
        })
    };

    const handleBuildingFeatureDelete = () => {
        deleteBuildingFeatureMutation({
            variables: {
                buildingFeatureId: get_building_feature(selectedFeatureId).id,
            },
            refetchQueries: [refetchQuery],
        }).then(response => {
            // handle the response
        });
    }

    const handleConfigFormSubmit = ({formData}, e) => {
        const bf = get_building_feature(selectedFeatureId)
        if (bf !== undefined && bf !== null) {
            updateBuildingFeatureMutation({
                variables: {
                    buildingFeatureId: bf.id,
                    config: formData,
                },
                refetchQueries: [refetchQuery],
            }).then(response => {
                // handle the response
            });
        } else {
            createBuildingFeatureMutation({
                variables: {
                    projectID: selectedBuilding.projectID,
                    featureId: selectedFeatureId,
                    config: formData,
                },
                refetchQueries: [refetchQuery],
            }).then(response => {
                // handle the response
            });
        }
    }


    const [isRunning, setIsRunning] = useState(false)
    const [finishedButtonName, setFinishedButtonName] = useState("")
    const [failureButtonName, setFailureButtonName] = useState("")
    const handleRunFeature = async ({formData}, button_name, nr_data) => {
        var data = {...nr_data, ...formData}
        const project_id = selectedBuilding.projectID
        if (get_building_feature(selectedFeatureId)) {
            const payload = {
                'feature_function_kwargs': {}
            }

            //remove unecessary kwargs
            for (let prop in data) {
                if (data.hasOwnProperty(prop)) {
                    if (['uitype', 'description'].includes(prop))
                        delete data[prop];
                }
            }

            // add defaults
            payload.feature_function_kwargs = data

            // add project_id, fearure_function_kwargs, feature_function_name and
            // feature_function_name if not already there.
            payload.feature_function_kwargs.project_id = project_id
            if (!payload.feature_function_kwargs.hasOwnProperty("feature_name")) {
                payload.feature_function_kwargs.feature_name = get_building_feature(selectedFeatureId).feature.name
            }
            if (!payload.feature_function_kwargs.hasOwnProperty("feature_function_name")) {
                payload.feature_function_kwargs.feature_function_name = data.hasOwnProperty('feature_function_name')
            }

            const envelope = {
                message: "io.deltaq.infra.command.function.call",
                subject: "project|" + project_id,
                source: "io.deltaq.app",
                data: JSON.stringify(payload)
            }

            setFinishedButtonName("")
            setFailureButtonName("")
            setIsRunning(true)

            const response = await axios.post(BuildingFeatureTriggerUrl, JSON.stringify(envelope), {
                headers: {
                    Authorization: `Bearer ${localStorage.sqb_auth0_access_token}`,
                    'Content-Type': 'application/json',
                }
            })
            if (response.status >= 200 && response.status < 300 && response?.data?.traceparent) {
                setFinishedButtonName(button_name);
            } else {
                setFailureButtonName(button_name);
            }
            setIsRunning(false)
        }
    };


    const get_button_config_schema = (selectedFeatureId, name) => (
        {
            type: "object",
            properties: Object.fromEntries(Object.entries(get_feature(selectedFeatureId)?.configSchema?.properties)?.filter(([k, v]) => name === k))
        }
    )

    const get_new_schema = (schema, data) => {
        var new_schema = {'type': 'object', 'properties': {}}
        var new_data = {}
        var nr_data = {}
        for (var attr in schema.properties) {
            if (schema.properties[attr].hasOwnProperty('render') && schema.properties[attr].render === true) {
                new_schema['properties'][attr] = {...schema.properties[attr]}
                new_data[attr] = data[attr]
            } else {
                nr_data[attr] = data[attr]
            }
        }
        return [new_schema, new_data, nr_data]
    }

    const printLogs = (fe) => {
        if ((fe.payload?.status_history ? true : false) && (!!fe.payload.status_history[Object.keys(fe.payload.status_history).sort()[Object.keys(fe.payload.status_history).length - 1]]?.logs)) {
            return (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key="n">
                    <Typography style={{color: 'red'}} key={fe.id} value={fe.id} variant="body2"
                                gutterBottom>logs: {fe.payload.status_history[Object.keys(fe.payload.status_history).sort()[Object.keys(fe.payload.status_history).length - 1]].logs}</Typography>
                </Grid>)
        } else {
            return null
        }
    }
    const get_button_ui_schema = (selectedFeatureId, name, projectID) => {
        const schema = get_button_config_schema(selectedFeatureId, name)
        var featureButton = Object.entries(schema.properties).map((Arr) =>
            Arr[1].properties !== undefined &&
            Arr[1].properties.hasOwnProperty("uitype") &&
            Object.values(Arr[1].properties.uitype).indexOf('button') > -1 ? [Arr[0]] : false);
        var uiOrder = featureButton.filter(Arr => Arr !== false).map(Arr => Arr[0])
        uiOrder.push("*")
        var uiSchema = {}

        uiSchema['ui:order'] = uiOrder
        featureButton.map((Arr) => Arr !== false ? uiSchema[Arr[0]] = {
            "ui:ObjectFieldTemplate": (props) => {
                var new_schema_data = get_new_schema(props.schema, props.formData)
                var new_data = new_schema_data[1]
                var nr_data = new_schema_data[2]
                return (
                    <div className="grid-container" style={{gridGap: '10px', display: 'grid'}}>
                        {/* Remove the MuiForm wrapper and keep only the button */}
                        <div className="grid-item">
                            <Button
                                style={{display: 'inline-block'}}
                                variant="contained"
                                color="primary"
                                type="button" // Change from submit to button
                                description={Arr[2]}
                                disabled={props.disabled || isRunning}
                                project_id={projectID}
                                onClick={() => handleRunFeature({formData: new_data}, name, nr_data)}>
                                {Arr[0]} {finishedButtonName === name && <DoneIcon/>} {failureButtonName === name &&
                                <ErrorOutlineIcon/>}
                            </Button>
                            {isRunning &&
                                <span style={{marginLeft: '10px', display: 'inline-block'}}><CircularProgress size={30}
                                                                                                              color="secondary"/></span>}
                        </div>
                    </div>
                )
            },
            "ui:disabled": isRunning
        } : false);
        return uiSchema
    }

    return (
        respMe.error || respFeatures.error
            ? (<Typography gutterBottom>Error fetching data!</Typography>)
            : respMe.loading || respFeatures.loading
                ? (<CircularProgress size={50} color="secondary"/>)
                : !selectedBuilding || !respFeatures.data.features
                    ? (<Typography gutterBottom>No data available - make sure you selected a building</Typography>)
                    : (
                        <Paper elevation={0} style={{padding: 25}}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key="selectFeature">
                                    <Select
                                        value={selectedFeatureId}
                                        onChange={handleFeatureChange}
                                        inputProps={{
                                            name: 'feature',
                                            id: 'select-feature',
                                        }}
                                    >
                                        <MenuItem key="-none-" value="-none-">-- Select Feature --</MenuItem>
                                        {features.map(f => (
                                            <MenuItem key={f.id}
                                                      value={f.id}>[{get_building_feature(f.id) ? get_building_feature(f.id).active ? 'v' : 'o' : '_'}] {get_category(f.id)} - {f.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} key="featureName">
                                    {get_building_feature(selectedFeatureId) !== undefined &&
                                        <Typography
                                            variant="h5">{get_feature(selectedFeatureId) ? "[" + get_category(selectedFeatureId) + "] " + get_feature(selectedFeatureId).name : ""}</Typography>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key="featureToggle" align="right">
                                    {get_building_feature(selectedFeatureId) !== undefined &&
                                        <Switch checked={get_building_feature(selectedFeatureId).active}
                                                onChange={(_event, checked) => checked ? handleBuildingFeatureActivation(get_building_feature(selectedFeatureId).id) : handleBuildingFeatureDeActivation(get_building_feature(selectedFeatureId).id)}/>
                                    }
                                </Grid>
                                {get_feature(selectedFeatureId) !== undefined &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key="featureSettings">
                                        {get_building_feature(selectedFeatureId) && (<>
                                            <Accordion key="featureFunctions" defaultExpanded={false}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                    <Typography variant="h6" gutterBottom>Functions</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div>
                                                        {get_feature_button_names(selectedFeatureId).map(name => (
                                                            <Accordion key={name} defaultExpanded={false}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                                    <Typography key="bn" variant="h6"
                                                                                gutterBottom>{name}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <MuiForm children={true}
                                                                             schema={get_button_config_schema(selectedFeatureId, name)}
                                                                             uiSchema={get_button_ui_schema(selectedFeatureId, name, selectedBuilding.projectID)}>
                                                                    </MuiForm>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion key="featureHistory" defaultExpanded={false}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                    <Typography variant="h6" gutterBottom>
                                                        History
                                                        &nbsp;&nbsp;&nbsp;
                                                        <IconButton aria-label="Refresh History" title="Refresh History"
                                                                    style={{padding: "0px"}} onClick={handleHistoryRefetch}>
                                                            {loadingHistory
                                                                ? (<CircularProgress size={24} color="secondary"/>)
                                                                : (<RefreshIcon/>)
                                                            }
                                                        </IconButton>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={3}>
                                                        {get_building_feature_events(selectedFeatureId).sort(fe => fe.eventDateTime).reverse().map(fe => (
                                                            <React.Fragment key={fe.id}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                                                      key={`n-${fe.id}`}>
                                                                    <Divider h="40px"/>
                                                                </Grid>
                                                                <Grid item xs={2} sm={2} md={2} lg={1} xl={1} key="s">
                                                                    {
                                                                        fe.status === "error" ?
                                                                            <ErrorOutlineIcon/> : fe.status === "success" ?
                                                                                <DoneIcon/> :
                                                                                <CircularProgress size={15} color="secondary"/>
                                                                    }
                                                                    <Typography key={fe.id} value={fe.id} variant="body2"
                                                                                gutterBottom> {fe.status}</Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} md={4} lg={2} xl={2} key="d">
                                                                    <Tooltip
                                                                        title={fe.eventDateTime.replace("T", " ").substring(0, 19)}>
                                                                        <Typography key={fe.id} value={fe.id}
                                                                                    variant="body2"
                                                                                    gutterBottom>{moment(fe.eventDateTime).fromNow()}</Typography>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} lg={9} xl={9} key="n">
                                                                    <Typography key={fe.id} value={fe.id} variant="body2"
                                                                                gutterBottom>{fe.eventType.replace("io.deltaq.project.event.buildingfeature." + get_feature(selectedFeatureId).name + ".", "")}</Typography>
                                                                </Grid>
                                                                <>
                                                                </>
                                                                {printLogs(fe)}
                                                            </React.Fragment>
                                                        ))}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>)}
                                        <Accordion key="featureConfig"
                                                   defaultExpanded={get_building_feature(selectedFeatureId) === undefined}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                <Typography variant="h6" gutterBottom>Configuration</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {get_feature(selectedFeatureId)
                                                    ? (get_building_feature(selectedFeatureId)?.feature?.name !== "configure-control" ? (<>
                                                        <br/>
                                                        <MuiForm onSubmit={handleConfigFormSubmit}
                                                                 schema={get_feature_config_schema(selectedFeatureId)}
                                                                 uiSchema={get_password_ui_schema(get_feature_config_schema(selectedFeatureId))}
                                                                 formData={get_building_feature(selectedFeatureId) !== undefined && get_building_feature(selectedFeatureId).config ? get_building_feature(selectedFeatureId).config : {}}>
                                                            <Button variant="contained" color="primary"
                                                                    type="submit">{get_building_feature(selectedFeatureId) === undefined ? "Add" : "Update"}</Button>
                                                            &nbsp;
                                                            {get_building_feature(selectedFeatureId) &&
                                                                <Button variant="contained" color="primary"
                                                                        onClick={handleBuildingFeatureDelete}>Delete</Button>}
                                                        </MuiForm>
                                                    </>) : (
                                                        <Accordion defaultExpanded={false}>
                                                            <Grid style={{width: "100%"}}>
                                                                <Accordion defaultExpanded={false}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                                        <Typography variant="h6" gutterBottom>Control
                                                                            overview</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <ConfigControl
                                                                            projectID={respMe.data.building.projectID}/>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion defaultExpanded={false}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                                        <Typography variant="h6" gutterBottom>Calendar
                                                                            overview</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <ConfigCalendar
                                                                            projectID={respMe.data.building.projectID}/>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion defaultExpanded={false}>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                                        <Typography variant="h6" gutterBottom>SteerMetrics
                                                                            overview</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <ConfigSteerMetrics
                                                                            projectID={respMe.data.building.projectID}
                                                                            formulaType={get_building_feature(selectedFeatureId)?.feature?.configSchema?.properties?.formula_type?.default ? get_building_feature(selectedFeatureId).feature.configSchema.properties.formula_type.default : ["Custom"]}/>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        </Accordion>

                                                    ))
                                                    : get_building_feature(selectedFeatureId) && get_building_feature(selectedFeatureId).config && JSON.stringify(get_building_feature(selectedFeatureId).config)
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                    )
    )
}
const getFeatures = gql`
query getFeatures($category: String!){
  features(where: {category: $category}) { 
    id name configSchema
    featureTags {id key value} 
    buildingFeatures {id active building {id}}}
}`

const getSelectedBuildingFeatureList = gql`
query getSelectedBuildingFeatureList($projectID: String!) {
  me {
    id email name role
  }
  building(where: {projectID: $projectID}) {
    id 
    projectID
    buildingFeatures {
      id 
      active
      config
      createdAt
      updatedAt
      feature {
        id
        name
        configSchema
        featureTags { 
          id
          key
          value 
        }
      }
    }
    activeControl {
      id
      feedbackControls {
        displayName
        parameters
        condition
      }
    }
  }
}`

const getSelectedBuildingEventList = gql`
query getSelectedBuildingEventList($projectID: String!) {
  me {
    id email name role
 }
 building(where: {projectID: $projectID}) {
    id projectID
    events {
      id eventDateTime eventType eventSubject eventSource eventTrace payload title text status
    }
 }
}`

const createBuildingFeature = gql`
mutation createBuildingFeature($projectID: String!, $featureId: ID!, $config: Json!) {
  createBuildingFeature ( projectID: $projectID featureId: $featureId config: $config state: {} ) { id }
}`;

const updateBuildingFeature = gql`
mutation updateBuildingFeature($buildingFeatureId: ID!, $config: Json!) {
  updateBuildingFeature ( id: $buildingFeatureId config: $config ) { id }
}`;

const activateBuildingFeature = gql`
mutation activateBuildingFeature($buildingFeatureId: ID!) {
  updateBuildingFeature ( id: $buildingFeatureId active: true ) { id }
}`;

const deActivateBuildingFeature = gql`
mutation deActivateBuildingFeature($buildingFeatureId: ID!) {
  updateBuildingFeature ( id: $buildingFeatureId active: false ) { id }
}`;

const deleteBuildingFeature = gql`
mutation deleteBuildingFeature($buildingFeatureId: ID!) {
  deleteBuildingFeature ( id: $buildingFeatureId ) { id }
}`;

// const runBuildingFeature = gql`
//   mutation runBuildingFeature($buildingFeatureId: ID!, $config: Json!) {
//     runBuildingFeature ( id: $buildingFeatureId config: $config) { id }
//   }`;

export default DataInputBuildingFeatureList;
