import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'
import useCreateSpace from "../../hooks/useCreateSpace";
import {useSystemMetricTags} from "../../contexts/systemMetricTagsContext";

const ConfigSpace = props => {
    const {projectID, spaceId, displayName, systemMetricTemplates, parentRefetch} = props

    const systemMetricTags = useSystemMetricTags()
    const [spaceDisplayName, setSpaceDisplayName] = useState(displayName)
    const [displayNameEditState, setDisplayNameEditState] = useState(false)
    const [openBuildingSpaceDialog, setOpenBuildingSpaceDialog] = useState(false)
    const [parentSpaceId, setParentSpaceId] = useState("none")
    const [selectedSystemMetricTemplateId, setSystemMetricTemplateId] = useState("---")
    const [expanded, setExpanded] = useState(false);
    const {createSystemMetricOnSpace} = useCreateSpace();

    const [getSpaceDetails, {data, loading, error, refetch}] = useLazyQuery(getSpaceData, {
        variables: {spaceId, projectID}
    })
    const [deleteSpaceMutation] = useMutation(deleteSpace)
    const [updateDisplayNameMutation] = useMutation(updateDisplayName)
    const [updateSpaceParentMutation] = useMutation(updateSpaceParent)


    useEffect(() => {
        if (data && data.space) {
            if (data.space.displayName && data.space.displayName.length > 0) {
                setSpaceDisplayName(data.space.displayName);
            } else {
                setDisplayNameEditState(true);
            }

            if (data.space.parentSpace) {
                setParentSpaceId(data.space.parentSpace.id);
            }
        }
    }, [data]);
    if (loading) return (<Grid><CircularProgress size={30} color="secondary"/></Grid>)
    if (error) return (<Grid><Typography gutterBottom>Error fetching Space data!</Typography></Grid>)

    const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key === "creator" && t.value === "configwizard")).map(t => (t.id))[0]

    const enableDisplayNameEdit = async () => {
        await setDisplayNameEditState(true)
    }
    const disableDisplayNameEdit = async () => {
        await setDisplayNameEditState(false)
    }

    const handleDisplayNameUpdate = async () => {
        await updateDisplayNameMutation({variables: {spaceId, spaceDisplayName}})
        await disableDisplayNameEdit()
    }

    const handleParentSpaceIdChange = async (event) => {
        await setParentSpaceId(event.target.value);
        await updateSpaceParentMutation({
            variables: {
                spaceId: spaceId,
                parentSpaceId: event.target.value,
            }
        });

        await refetch()
    };

    const handleSpaceDelete = async () => {
        await deleteSpaceMutation({variables: {spaceId}})
        await parentRefetch()
    }

    const closeBuildingSpaceDialog = async () => {
        setOpenBuildingSpaceDialog(false)
    };

    const handleSystemMetricAdd = async () => {
        if (selectedSystemMetricTemplateId !== "---") {
            await createSystemMetricOnSpace({
                projectID: projectID,
                spaceId: spaceId,
                systemMetricTemplateId: selectedSystemMetricTemplateId,
                payload: {},
                payloadType: "",
                userInput: {},
                aggregation: "",
                systemMetricTags: [
                    {"id": systemMetricTagCreatorConfigwizardId},
                    {"id": systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
                ],
                rawTrajectories: [],
                profileContextCreateOneWithoutSystemMetricConversionsInput: null,
            })
            await refetch()
        }
    }
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded) {
            getSpaceDetails();
        }
    }

    return (
        <Accordion TransitionProps={{unmountOnExit: true}} expanded={expanded} onChange={handleAccordionChange}>

            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="h6">{displayName}</Typography>

                <Typography variant="h6" style={{flex: 1}}>&nbsp;</Typography>
                <div onClick={(e) => e.stopPropagation()} style={{display: 'flex'}}>
                    <Hidden xlDown={displayNameEditState}>
                        <Icon onClick={enableDisplayNameEdit}>edit</Icon>
                    </Hidden>
                    <Hidden xlDown={!displayNameEditState}>
                        <Input
                            id="displayName-input"
                            placeholder={spaceDisplayName + " Name"}
                            value={spaceDisplayName}
                            onChange={(event) => {
                                setSpaceDisplayName(event.target.value)
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
                            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
                            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
                        </Button>
                    </Hidden>
                </div>
                <Typography variant="h6">&nbsp;</Typography>
            </AccordionSummary>


            <AccordionDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>The parent space this space belongs
                                    to</Typography>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="select-parentSpaceId"
                                    select
                                    value={parentSpaceId}
                                    onChange={handleParentSpaceIdChange}
                                    margin="normal"
                                >
                                    <MenuItem key="none" value="none">
                                        -- Select space --
                                    </MenuItem>
                                    {data && data.spaces.map(space => (
                                        <MenuItem key={space.id} value={space.id}>
                                            {space.displayName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="select-system-metric-template"
                                    select
                                    value={selectedSystemMetricTemplateId}
                                    onChange={(event) => {
                                        setSystemMetricTemplateId(event.target.value)
                                    }}
                                    margin="normal"
                                >
                                    <MenuItem key={"---"} value={"---"}>
                                        -- Select Metric to Add --
                                    </MenuItem>
                                    {systemMetricTemplates
                                        .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                                        .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                                        .map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.displayName}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </TableCell>
                            <TableCell align="right">
                                <Button size="small" variant="contained" color="primary"
                                        onClick={handleSystemMetricAdd}>
                                    <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {data && data.space.systemMetrics
                            .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate?.displayName > b.systemMetricTemplate?.displayName ? 1 : -1)))
                            .map((systemMetric, index) => {
                                return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
                                    ? (
                                        <ConfigSystemMetricEdit
                                            key={systemMetric.id}
                                            projectID={projectID}
                                            systemMetric={systemMetric}
                                            profileContexts={data.profileContexts}
                                            systemMetricId={systemMetric.id}
                                            parentRefetch={refetch}/>
                                    ) : null)
                            })}
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button size="small" variant="contained" color="primary" onClick={handleSpaceDelete}>
                    <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;Space&nbsp;"{spaceDisplayName}"&nbsp;
                </Button>
                <Dialog
                    open={openBuildingSpaceDialog}
                    onClose={closeBuildingSpaceDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Cannot delete main Building Space"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The Building Space is required for a correct functioning of the application.
                            Please create a hierarchical structure by adding all spaces as children of the Building
                            Space or descendants thereof.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeBuildingSpaceDialog} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </AccordionActions>
        </Accordion>
    );
}

const deleteSpace = gql`
mutation deleteSpace($spaceId: ID!) {
  deleteSpace(id: $spaceId) {
    id
  }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($spaceId: ID!, $spaceDisplayName: String!) {
  updateSpaceDisplayName (
    id: $spaceId
    displayName: $spaceDisplayName
  ) { id displayName }
}`;

const updateSpaceParent = gql`
mutation updateSpaceParent($spaceId: ID!, $parentSpaceId: ID!) {
  updateSpaceParent (
    id: $spaceId
    parentSpaceId: $parentSpaceId
  ) {
    id
    displayName
    parentSpace { id displayName }
  }
}`;
const getSpaceData = gql`
query getSpaceData($spaceId: ID!, $projectID: String!) {
  profileContexts (projectID: $projectID) {
    id
    displayName
  }
  spaces (projectID: $projectID) {
    id
    displayName
    slug
  }
  space (id: $spaceId) {
    id
    displayName
    slug
    parentSpace {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        description
        displayName
        guiOrder
        level
        possibleFunctionTags {id key value}
        metric {
            id
            defaultOperationFormula
            defaultDisplayUnitString
            alternativeUnitStrings
        }
      }
      systemMetricConversionIn {
        id
        operationFormula
        operationInputMap
        sourceProfileContext {id displayName}
        sourceRawTrajectories {
            id
            tagname
            userInputUnit
            userInputCumulative
            userInputRating
            userInputCounterResetValue
            autoSuggestUnit
            autoSuggestCumulative
            autoSuggestCounterResetValue
          }
        }
    }
  }
}`;


export default ConfigSpace
