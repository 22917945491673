import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'
import {makeStyles} from '@material-ui/core/styles';
import {useSystemMetricTags} from "../../contexts/systemMetricTagsContext";

const useStyles = makeStyles((theme) => ({
    wideInput: {
        width: '400px',
        marginRight: '12px',
    },
}));

const ConfigSystem = props => {
    const classes = useStyles();

    const {projectID, systemId, displayName, systemMetricTemplates, parentRefetch} = props
    const systemMetricTags = useSystemMetricTags()

    const [systemDisplayName, setSystemDisplayName] = useState("")
    const [displayNameEditState, setDisplayNameEditState] = useState(false)
    const [selectedSpaceIds, setSpaceIds] = useState([])
    const [selectedSystemMetricTemplateId, setSystemMetricTemplateId] = useState("---")
    const [expanded, setExpanded] = useState(false);

    const [getSystemDetails, {data, loading, error, refetch}] = useLazyQuery(getSystemData, {
        variables: {systemId, projectID}
    })

    const [deleteSystemMutation] = useMutation(deleteSystem)
    const [updateDisplayNameMutation] = useMutation(updateDisplayName)
    const [updateSystemSpacesMutation] = useMutation(updateSystemSpaces)
    const [createSystemMetricOnSystemMutation] = useMutation(createSystemMetricOnSystem)
    useEffect(() => {
        if (data && data.system) {
            if (data.system.displayName && data.system.displayName.length > 0) {
                setSystemDisplayName(data.system.displayName);
            } else {
                setDisplayNameEditState(true);
            }

            if (data.system.spaces) {
                setSpaceIds(data.system.spaces.map(space => space.id));
            }
        }
    }, [data]);
    if (loading) return (<Grid><CircularProgress size={30} color="secondary"/></Grid>)
    if (error) return (<Grid><Typography gutterBottom>Error fetching System data!</Typography></Grid>)

    const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key === "creator" && t.value === "configwizard")).map(t => (t.id))[0]

    const enableDisplayNameEdit = async () => {
        await setDisplayNameEditState(true)
    }
    const disableDisplayNameEdit = async () => {
        await setDisplayNameEditState(false)
    }

    const handleDisplayNameUpdate = async () => {
        await updateDisplayNameMutation({variables: {"systemId": systemId, "displayName": systemDisplayName}})
        await disableDisplayNameEdit()
    }

    const handleSelectedSpaceIdsChange = async (event) => {
        await setSpaceIds(event.target.value);
        await updateSystemSpacesMutation({
            variables: {
                systemId: systemId,
                spaces: event.target.value.map(id => ({"id": id})),
            }
        });
        await refetch()
    };

    const handleSystemDelete = async () => {
        await deleteSystemMutation({variables: {systemId}})
        await parentRefetch()
    }

    const handleSystemMetricAdd = async () => {
        if (selectedSystemMetricTemplateId !== "---") {
            await createSystemMetricOnSystemMutation({
                variables: {
                    projectID: projectID,
                    systemId: systemId,
                    systemMetricTemplateId: selectedSystemMetricTemplateId,
                    payload: {},
                    userInput: {},
                    aggregation: "",
                    systemMetricTags: [
                        {"id": systemMetricTagCreatorConfigwizardId},
                        {"id": data.system.systemTemplate.systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
                    ],
                    rawTrajectories: [],
                    profileContextCreateOneWithoutSystemMetricConversionsInput: null,
                }
            })
            await refetch()
        }
    }
    const handleSelectAllClick = () => {
        if (selectedSpaceIds.length === data.spaces.length) {
            setSpaceIds([]);
        } else {
            setSpaceIds(data.spaces.map(space => space.id));
        }
    };
    const isAllSelected = data && selectedSpaceIds.length === data.spaces.length;

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded) {
            getSystemDetails();
        }
    }
    return (
        <Accordion TransitionProps={{unmountOnExit: true}} expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="h6">{displayName}</Typography>
                <Typography variant="h6" style={{flex: 1}}>&nbsp;</Typography>
                <div onClick={(e) => e.stopPropagation()} style={{display: 'flex'}}>
                    <Hidden xlDown={displayNameEditState}>
                        <Icon onClick={enableDisplayNameEdit}>edit</Icon>
                    </Hidden>
                    <Hidden xlDown={!displayNameEditState}>
                        <Input
                            id="displayName-input"
                            placeholder={systemDisplayName + " Name"}
                            value={systemDisplayName}
                            onChange={(event) => {
                                setSystemDisplayName(event.target.value)
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
                            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
                            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
                        </Button>
                    </Hidden>
                </div>
                <Typography variant="h6">&nbsp;</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Related Space(s)</Typography>
                            </TableCell>
                            <TableCell>
                                <Select multiple
                                        value={selectedSpaceIds}
                                        onChange={handleSelectedSpaceIdsChange}
                                        input={<Input id="spaces-multiple"/>}
                                        renderValue={selected => data.spaces.filter(space => selected.indexOf(space.id) > -1).map(space => space.displayName).join(', ')}
                                        className={classes.wideInput}>
                                    {data && data.spaces.map(space => (
                                        <MenuItem key={space.id} value={space.id}>
                                            <Checkbox checked={selectedSpaceIds.indexOf(space.id) > -1}/>
                                            <ListItemText primary={space.displayName}/>
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Button size="small" variant="contained" color="primary" onClick={handleSelectAllClick}>
                                    {isAllSelected ? 'Select None' : 'Select All'}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="select-system-metric-template"
                                    select
                                    value={selectedSystemMetricTemplateId}
                                    onChange={(event) => {
                                        setSystemMetricTemplateId(event.target.value)
                                    }}
                                    margin="normal"
                                    className={classes.wideInput}
                                >
                                    <MenuItem key={"---"} value={"---"}>
                                        -- Select Metric to Add --
                                    </MenuItem>
                                    {systemMetricTemplates
                                        .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                                        .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                                        .map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.displayName}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </TableCell>
                            <TableCell align="right">
                                <Button size="small" variant="contained" color="primary"
                                        onClick={handleSystemMetricAdd}>
                                    <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {data && data.system.systemMetrics
                            .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
                            .map((systemMetric, index) => {
                                return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
                                    ? (
                                        <ConfigSystemMetricEdit
                                            key={systemMetric.id}
                                            projectID={projectID}
                                            systemMetric={systemMetric}
                                            profileContexts={data.profileContexts}
                                            systemMetricId={systemMetric.id}
                                            parentRefetch={refetch}/>
                                    ) : null)
                            })}
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button size="small" variant="contained" color="primary" onClick={handleSystemDelete}>
                    <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;{data?.system?.systemTemplate?.displayName}&nbsp;"{displayName}"&nbsp;
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

const deleteSystem = gql`
mutation deleteSystem($systemId: ID!) {
  deleteSystem(id: $systemId) { id }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($systemId: ID!, $displayName: String!) {
  updateSystemDisplayName(
    id: $systemId
    displayName: $displayName
  ) { id displayName }
}`;

const updateSystemSpaces = gql`
mutation updateSystemSpaces($systemId: ID!, $spaces: [SpaceWhereUniqueInput!]) {
  updateSystemSpaces (
    id: $systemId
    spaces: $spaces
  ) {
    id
    displayName
    spaces { id displayName }
  }
}`;

const createSystemMetricOnSystem = gql`
mutation createSystemMetricOnSystem($projectID: String!, $systemId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnSystem(
    projectID: $projectID
    systemId: $systemId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const getSystemData = gql`
query getSystemData($systemId: ID!, $projectID: String!) {
  profileContexts (projectID: $projectID) {
    id
    displayName
  }
  spaces (projectID: $projectID) {
    id
    displayName
    slug
  }
  system (id: $systemId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    spaces {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
            systemMetricTemplate {
        id
        name
        displayName
        description
        guiOrder
        level
        possibleFunctionTags {id key value}
        metric {
            id
            defaultOperationFormula
            defaultDisplayUnitString
            alternativeUnitStrings
        }
      }
      systemMetricConversionIn {
        id
        operationFormula
        operationInputMap
        sourceProfileContext {id displayName}
        sourceRawTrajectories {
            id
            tagname
            userInputUnit
            userInputCumulative
            userInputRating
            userInputCounterResetValue
            autoSuggestUnit
            autoSuggestCumulative
            autoSuggestCounterResetValue
          }
        }
    }
  }
}`;


export default ConfigSystem
