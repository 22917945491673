import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import TopologyVisualization from './TopologyVisualization';

const ConfigComponentTopology = (props) => {
    const projectID = props.match.params.projectID;
    const {data, loading, error} = useQuery(getSystemsTopology, {
        variables: {projectID}
    });

    if (loading) return (
        <Grid container justifyContent="center" alignItems="center" style={{minHeight: '200px'}}>
            <CircularProgress size={50} color="secondary"/>
        </Grid>
    );

    if (error) return (
        <Grid container>
            <Typography color="error" gutterBottom>
                Error fetching building configuration!
            </Typography>
        </Grid>
    );

    if (!data?.building?.systems && !data?.building?.waterCircuits) return (
        <Typography gutterBottom>No Data Found!</Typography>
    );

    return (
        <Box>
            <TopologyVisualization data={data}/>
        </Box>
    );
};

const getSystemsTopology = gql`
  query getSpaceSystemMetrics($projectID: String!) {
    building(where: { projectID: $projectID }) {
      systems {
        id
        displayName
        systemTemplate {
          name
        }
      }
      waterCircuits {
        displayName
        sourceSystems {
          id
        }
        destinationSystems {
          id
        }
      }
    }
  }
`;

export default ConfigComponentTopology;