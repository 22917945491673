import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const NotCoupledSystemsTable = ({projectID}) => {
    const {data, loading, error} = useQuery(getSystemsAndCircuits, {
        variables: {projectID}
    });

    if (loading) return (
        <Grid container justifyContent="center" alignItems="center" style={{minHeight: '200px'}}>
            <CircularProgress size={50} color="secondary"/>
        </Grid>
    );

    if (error) return (
        <Grid container>
            <Typography color="error" gutterBottom>
                Error fetching systems data!
            </Typography>
        </Grid>
    );

    if (!data?.building?.systems) return (
        <Typography gutterBottom>No Systems Found!</Typography>
    );

    const coupledSystemIds = new Set();
    data.building.waterCircuits.forEach(circuit => {
        circuit.sourceSystems.forEach(system => coupledSystemIds.add(system.id));
        circuit.destinationSystems.forEach(system => coupledSystemIds.add(system.id));
    });

    const uncoupledSystems = data.building.systems.filter(system => !coupledSystemIds.has(system.id));

    // Group uncoupled systems by systemTemplate.displayName
    const groupedSystems = uncoupledSystems.reduce((acc, system) => {
        const templateName = system.systemTemplate.displayName;
        if (!acc[templateName]) {
            acc[templateName] = [];
        }
        acc[templateName].push(system);
        return acc;
    }, {});

    return (
        <Box>
            {Object.keys(groupedSystems).map(templateName => (
                <React.Fragment key={templateName}>
                    <Typography variant="h6">{templateName}</Typography>
                    <ul>
                        {groupedSystems[templateName].map(system => (
                            <li key={system.id}>
                                {system.displayName}
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </Box>
    );
};

const getSystemsAndCircuits = gql`
  query getSystemsAndCircuits($projectID: String!) {
    building(where: { projectID: $projectID }) {
      systems {
        id
        displayName
        systemTemplate {
            name
            displayName
        }
      }
      waterCircuits {
        sourceSystems {
          id
        }
        destinationSystems {
          id
        }
      }
    }
  }
`;

export default NotCoupledSystemsTable;