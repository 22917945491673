import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import * as d3Hierarchy from 'd3-hierarchy';

const getSystemType = (systemTemplate) => {
    const name = systemTemplate.name;
    if (name.includes('_cp') || name.includes('_hp')) {
        return 'production';
    } else if (name.includes('_collector')) {
        return 'collector';
    }
    return 'terminal';
};

const determineCircuitType = (sourceSystem, destSystem) => {
    if (sourceSystem.systemTemplate.name.includes('_hp')) {
        return 'hot';
    }
    if (sourceSystem.systemTemplate.name.includes('_cp')) {
        return 'cold';
    }
    return sourceSystem.circuitType || 'neutral';
};

const buildHierarchy = (systems, waterCircuits) => {
    const systemsMap = new Map(systems.map(s => [s.id, {...s, children: []}]));

    waterCircuits.forEach(circuit => {
        circuit.sourceSystems.forEach(source => {
            const sourceSystem = systemsMap.get(source.id);

            circuit.destinationSystems.forEach(dest => {
                const destSystem = systemsMap.get(dest.id);
                const connection = {
                    ...destSystem,
                    circuitName: circuit.displayName,
                    circuitType: determineCircuitType(sourceSystem, destSystem)
                };
                sourceSystem.children.push(connection);
            });
        });
    });

    const roots = Array.from(systemsMap.values()).filter(system =>
        (system.systemTemplate.name === 'systemTemplate_cp' ||
            system.systemTemplate.name === 'systemTemplate_hp') &&
        system.children.length > 0
    );

    return roots;
};

const TopologyVisualization = ({ data }) => {
    const containerRef = useRef(null);
    const svgRef = useRef(null);
    const { systems, waterCircuits } = data.building;

    useEffect(() => {
        if (!systems || !waterCircuits || !containerRef.current) return;

        // Get container dimensions
        const containerWidth = containerRef.current.clientWidth;
        const minHeight = 400; // Minimum height
        const margin = { top: 40, right: 100, bottom: 40, left: 100 };
        const innerWidth = containerWidth - margin.left - margin.right;

        // Clear previous content
        const svg = svgRef.current;
        while (svg.firstChild) {
            svg.removeChild(svg.firstChild);
        }

        // Create root g element
        const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
        g.setAttribute("transform", `translate(${margin.left},${margin.top})`);
        svg.appendChild(g);

        // Create hierarchy
        const roots = buildHierarchy(systems, waterCircuits);
        const hierarchyData = {
            displayName: "Root",
            children: roots
        };

        // Create tree layout
        const root = d3Hierarchy.hierarchy(hierarchyData);

        // Calculate number of levels and maximum nodes at any level
        let maxNodesAtLevel = 0;
        const nodesByLevel = {};
        root.each(node => {
            const level = node.depth;
            if (!nodesByLevel[level]) nodesByLevel[level] = 0;
            nodesByLevel[level]++;
            maxNodesAtLevel = Math.max(maxNodesAtLevel, nodesByLevel[level]);
        });

        // Calculate optimal node spacing
        const horizontalSpacing = innerWidth / (Object.keys(nodesByLevel).length || 1);
        const minVerticalSpacing = 40; // Minimum vertical spacing between nodes
        const verticalSpacing = Math.max(minVerticalSpacing, minHeight / maxNodesAtLevel);

        const tree = d3Hierarchy.tree()
            .nodeSize([verticalSpacing, horizontalSpacing])
            .separation((a, b) => 1);

        const treeData = tree(root);

        // Normalize coordinates to start from 0
        let minX = Infinity;
        treeData.each(d => {
            minX = Math.min(minX, d.x);
        });
        treeData.each(d => {
            d.x -= minX;
        });

        // Calculate actual height needed
        const height = Math.max(minHeight,
            Math.max(...treeData.descendants().map(d => d.x)) + margin.top + margin.bottom);

        svg.setAttribute("width", containerWidth);
        svg.setAttribute("height", height);

        // Create links
        const linkGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
        g.appendChild(linkGroup);

        treeData.links().forEach(d => {
            const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            const midY = (d.source.y + d.target.y) / 2;

            path.setAttribute("d", `M${d.source.y},${d.source.x}
                            L${midY},${d.source.x}
                            L${midY},${d.target.x}
                            L${d.target.y},${d.target.x}`);
            path.setAttribute("fill", "none");
            path.setAttribute("stroke", '#666');
            path.setAttribute("stroke-width", "1.5");
            linkGroup.appendChild(path);

            // Add circuit name
            const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
            text.setAttribute("x", midY);
            text.setAttribute("y", (d.source.x + d.target.x) / 2 - 5);
            text.setAttribute("text-anchor", "middle");
            text.setAttribute("font-size", "10px");
            text.setAttribute("fill", "#666");
            text.textContent = d.target.data.circuitName || "";
            linkGroup.appendChild(text);
        });

        // Create nodes
        const nodeGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
        g.appendChild(nodeGroup);
        treeData.descendants().forEach(d => {
            if (d.data.systemTemplate) {
                const nodeG = document.createElementNS("http://www.w3.org/2000/svg", "g");
                nodeG.setAttribute("transform", `translate(${d.y},${d.x})`);

                const type = getSystemType(d.data.systemTemplate);
                const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
                circle.setAttribute("r", "6");
                circle.setAttribute("fill", type === 'production' ? '#f8d7da' :
                    type === 'collector' ? '#cce5ff' : '#d4edda');
                circle.setAttribute("stroke", "#666");
                circle.setAttribute("stroke-width", "1.5");
                nodeG.appendChild(circle);

                const text = document.createElementNS("http://www.w3.org/2000/svg", "text");

                text.setAttribute("dx", d.data.displayName.length * -3);
                text.setAttribute("dy", "20");
                text.setAttribute("font-size", "12px");
                text.textContent = d.data.displayName;
                nodeG.appendChild(text);

                nodeGroup.appendChild(nodeG);
            }
        });

    }, [systems, waterCircuits]);

    return (
        <Box ref={containerRef} width="100%" height="80vh" overflow="auto">
            <svg ref={svgRef} style={{ minWidth: '100%' }} />
        </Box>
    );
};

TopologyVisualization.propTypes = {
    data: PropTypes.shape({
        building: PropTypes.shape({
            systems: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
                systemTemplate: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired
            })).isRequired,
            waterCircuits: PropTypes.arrayOf(PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                sourceSystems: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.string.isRequired
                })).isRequired,
                destinationSystems: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.string.isRequired
                })).isRequired
            })).isRequired
        }).isRequired
    }).isRequired
};

export default TopologyVisualization;