import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'
import {makeStyles} from "@material-ui/core/styles";
import {useSystemMetricTags} from "../../contexts/systemMetricTagsContext";

const useStyles = makeStyles((theme) => ({
    wideInput: {
        width: '400px',
        marginRight: '12px',
    },
}));

const ConfigWaterCircuit = props => {
    const {projectID, waterCircuitId, displayName, systemMetricTemplates, parentRefetch} = props
    const classes = useStyles();
    const systemMetricTags = useSystemMetricTags()
    const [waterCircuitDisplayName, setWaterCircuitDisplayName] = useState(displayName)
    const [displayNameEditState, setDisplayNameEditState] = useState(false)
    const [selectedSourceSystemIds, setSourceSystemIds] = useState([])
    const [selectedDestinationSystemIds, setDestinationSystemIds] = useState([])
    const [selectedSystemMetricTemplateId, setSystemMetricTemplateId] = useState("---")
    const [expanded, setExpanded] = useState(false);

    const [getWaterCircuitDetails, {data, loading, error, refetch}] = useLazyQuery(getWaterCircuitData, {
        variables: {waterCircuitId, projectID},
    })

    const [deleteWaterCircuitMutation] = useMutation(deleteWaterCircuit)
    const [updateDisplayNameMutation] = useMutation(updateDisplayName)
    const [updateWaterCircuitSourceSystemsMutation] = useMutation(updateWaterCircuitSourceSystems)
    const [updateWaterCircuitDestinationSystemsMutation] = useMutation(updateWaterCircuitDestinationSystems)
    const [createSystemMetricOnWaterCircuitMutation] = useMutation(createSystemMetricOnWaterCircuit)

    useEffect(() => {
        if (data && data.waterCircuit) {
            if (data.waterCircuit.displayName && data.waterCircuit.displayName.length > 0) {
                setWaterCircuitDisplayName(data.waterCircuit.displayName);
            } else {
                setDisplayNameEditState(true);
            }

            if (data.waterCircuit.sourceSystems) {
                setSourceSystemIds(data.waterCircuit.sourceSystems.map(system => system.id))
            }
            if (data.waterCircuit.destinationSystems) {
                setDestinationSystemIds(data.waterCircuit.destinationSystems.map(system => system.id))
            }
        }
    }, [data]);


    if (loading) return (<Grid><CircularProgress size={30} color="secondary"/></Grid>)
    if (error) return (<Grid><Typography gutterBottom>Error fetching WaterCircuit data!</Typography></Grid>)


    const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key === "creator" && t.value === "configwizard")).map(t => (t.id))[0]

    const enableDisplayNameEdit = async () => {
        await setDisplayNameEditState(true)
    }
    const disableDisplayNameEdit = async () => {
        await setDisplayNameEditState(false)
    }

    const handleDisplayNameUpdate = async () => {
        await updateDisplayNameMutation({variables: {waterCircuitId, displayName: waterCircuitDisplayName}})
        await disableDisplayNameEdit()
    }

    const handleSelectedSourceSystemIdsChange = async (event) => {
        await setSourceSystemIds(event.target.value);
        await updateWaterCircuitSourceSystemsMutation({
            variables: {
                waterCircuitId: waterCircuitId,
                sourceSystems: event.target.value.map(id => ({"id": id})),
            }
        });
        await refetch()
    };

    const handleSelectedDestinationSystemIdsChange = async (event) => {
        await setDestinationSystemIds(event.target.value);
        await updateWaterCircuitDestinationSystemsMutation({
            variables: {
                waterCircuitId: waterCircuitId,
                destinationSystems: event.target.value.map(id => ({"id": id})),
            }
        });
        await refetch()
    };

    const handleWaterCircuitDelete = async () => {
        await deleteWaterCircuitMutation({variables: {waterCircuitId}})
        await parentRefetch()
    }

    const handleSystemMetricAdd = async () => {
        if (selectedSystemMetricTemplateId !== "---") {
            await createSystemMetricOnWaterCircuitMutation({
                variables: {
                    projectID: projectID,
                    waterCircuitId: waterCircuitId,
                    systemMetricTemplateId: selectedSystemMetricTemplateId,
                    payload: {},
                    userInput: {},
                    aggregation: "",
                    systemMetricTags: [
                        {"id": systemMetricTagCreatorConfigwizardId},
                        {"id": systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
                    ],
                    rawTrajectories: [],
                    profileContextCreateOneWithoutSystemMetricConversionsInput: null,
                }
            })
            await refetch()
        }
    }
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded) {
            getWaterCircuitDetails();
        }
    }

    return (
        <Accordion TransitionProps={{unmountOnExit: true}} expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant="h6">{waterCircuitDisplayName}</Typography>
                <Typography variant="h6" style={{flex: 1}}>&nbsp;</Typography>
                <div onClick={(e) => e.stopPropagation()} style={{display: 'flex'}}>

                    <Hidden xlDown={displayNameEditState}>
                        <Icon onClick={enableDisplayNameEdit}>edit</Icon>
                    </Hidden>
                    <Hidden xlDown={!displayNameEditState}>
                        <Input
                            id="displayName-input"
                            placeholder={waterCircuitDisplayName + " Name"}
                            value={waterCircuitDisplayName}
                            onChange={(event) => {
                                setWaterCircuitDisplayName(event.target.value)
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
                            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
                            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
                        </Button>
                    </Hidden>
                </div>
                <Typography variant="h6">&nbsp;</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Source System(s)</Typography>
                            </TableCell>
                            <TableCell>
                                <Select
                                    multiple
                                    value={selectedSourceSystemIds}
                                    onChange={handleSelectedSourceSystemIdsChange}
                                    input={<Input id="source-system-multiple"/>}
                                    renderValue={selected => data && data.systems.filter(system => selected.indexOf(system.id) > -1).map(system => system.displayName).join(', ')}
                                    className={classes.wideInput}>
                                    {data && data.systems
                                        .filter(system=>["systemTemplate_collector", "systemTemplate_cp","systemTemplate_hp"].includes(system.systemTemplate.name))
                                        .map(system => (
                                        <MenuItem key={system.id} value={system.id}>
                                            <Checkbox checked={selectedSourceSystemIds.indexOf(system.id) > -1}/>
                                            <ListItemText primary={system.displayName}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Destination System(s)</Typography>
                            </TableCell>
                            <TableCell>
                                <Select
                                    multiple
                                    value={selectedDestinationSystemIds}
                                    onChange={handleSelectedDestinationSystemIdsChange}
                                    input={<Input id="destination-system-multiple"/>}
                                    renderValue={selected => data && data.systems.filter(system => selected.indexOf(system.id) > -1).map(system => system.displayName).join(', ')}
                                    className={classes.wideInput}>
                                    {data && data.systems
                                        .filter(system=>["systemTemplate_collector", "systemTemplate_ahu","systemTemplate_dhw","systemTemplate_emihea","systemTemplate_emicoo","systemTemplate_endunit"].includes(system.systemTemplate.name))
                                        .map(system => (
                                        <MenuItem key={system.id} value={system.id}>
                                            <Checkbox checked={selectedDestinationSystemIds.indexOf(system.id) > -1}/>
                                            <ListItemText primary={system.displayName}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="select-system-metric-template"
                                    select
                                    value={selectedSystemMetricTemplateId}
                                    onChange={(event) => {
                                        setSystemMetricTemplateId(event.target.value)
                                    }}
                                    margin="normal"
                                >
                                    <MenuItem key={"---"} value={"---"}>
                                        -- Select Metric to Add --
                                    </MenuItem>
                                    {systemMetricTemplates
                                        .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                                        .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                                        .map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.displayName}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </TableCell>
                            <TableCell align="right">
                                <Button size="small" variant="contained" color="primary"
                                        onClick={handleSystemMetricAdd}>
                                    <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {data && data.waterCircuit.systemMetrics
                            .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
                            .map((systemMetric, index) => {
                                return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
                                    ? (
                                        <ConfigSystemMetricEdit
                                            key={systemMetric.id}
                                            projectID={projectID}
                                            systemMetric={systemMetric}
                                            profileContexts={data.profileContexts}
                                            systemMetricId={systemMetric.id}
                                            parentRefetch={refetch}/>
                                    ) : null)
                            })}
                    </TableBody>
                </Table>
            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button size="small" variant="contained" color="primary" onClick={handleWaterCircuitDelete}>
                    <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;Watercircuit &nbsp;"{waterCircuitDisplayName}"&nbsp;
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

const deleteWaterCircuit = gql`
mutation deleteWaterCircuit($waterCircuitId: ID!) {
  deleteWaterCircuit(
    id: $waterCircuitId
  ) {
    id
  }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($waterCircuitId: ID!, $displayName: String!) {
  updateWaterCircuitDisplayName (
    id: $waterCircuitId
    displayName: $displayName
  ) { id displayName }
}`;


const updateWaterCircuitSourceSystems = gql`
mutation updateWaterCircuitSourceSystems($waterCircuitId: ID!, $sourceSystems: [SystemWhereUniqueInput!]) {
  updateWaterCircuitSourceSystems (
    id: $waterCircuitId
    sourceSystems: $sourceSystems
  ) {
    id
    displayName
    sourceSystems {
      id
      displayName
    }
  }
}`;

const updateWaterCircuitDestinationSystems = gql`
mutation updateWaterCircuitDestinationSystems($waterCircuitId: ID!, $destinationSystems: [SystemWhereUniqueInput!]) {
  updateWaterCircuitDestinationSystems (
    id: $waterCircuitId
    destinationSystems: $destinationSystems
  ) {
    id
    displayName
    destinationSystems {
      id
      displayName
    }
  }
}`;

const createSystemMetricOnWaterCircuit = gql`
mutation createSystemMetricOnWaterCircuit($projectID: String!, $waterCircuitId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnWaterCircuit(
    projectID: $projectID
    waterCircuitId: $waterCircuitId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const getWaterCircuitData = gql`
query updateCache($waterCircuitId: ID!, $projectID: String!) {
  profileContexts (projectID: $projectID) {
    id
    displayName
  }
  systems (projectID: $projectID) {
    id
    displayName
    slug
    systemTemplate{
        name
    }
  }
  waterCircuit (id: $waterCircuitId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    sourceSystems {
      id
      displayName
      slug
    }
    destinationSystems {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        displayName
        description
        guiOrder
        level
        possibleFunctionTags {id key value}
        metric {
            id
            defaultOperationFormula
            defaultDisplayUnitString
            alternativeUnitStrings
        }
      }
      systemMetricConversionIn {
        id
        operationFormula
        operationInputMap
        sourceProfileContext {id displayName}
        sourceRawTrajectories {
            id
            tagname
            userInputUnit
            userInputCumulative
            userInputRating
            userInputCounterResetValue
            autoSuggestUnit
            autoSuggestCumulative
            autoSuggestCounterResetValue
          }
        }
    }
  }
}`;

export default ConfigWaterCircuit